import { Action } from 'types/index';

export enum WidgetSettingsActionTypes {
  Loaded = 'WidgetSettings.Loaded',
}

export type WidgetSettingsState = {
  default_language: string;
  available_languages: string[];
  confirmation_type: string;
  show_rooms: boolean;
  min_guests: number;
  max_guests: number;
  booking_ahead: number;
  booking_time: number;
  update_time: number;
  cancel_time: number;
  max_slot_guests: number | null;
  max_day_guests: number | null;
  waiting_list: boolean;
};

export interface WidgetSettingsAction extends Action<WidgetSettingsState> {
  type: WidgetSettingsActionTypes;
}
