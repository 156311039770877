import { Action } from 'types/index';

export enum RestaurantActionTypes {
  Loaded = 'Restaurant.Loaded',
}

export interface RestaurantLogo {
  id: number;
  name: string;
  url: string;
  mime: string;
  size: string;
}

export interface RestaurantInfo {
  name?: string;
  phone?: string;
  email?: string;
  website?: string;
  logo?: RestaurantLogo | null;
}

export interface RestaurantAddress extends PlaceType {
  country?: string;
  administrative_area?: string;
  sub_administrative_area?: string;
  locality?: string;
  dependent_locality?: string;
  postal_code?: string;
  thoroughfare?: string;
  premise?: string;
  sub_premise?: string;
}

export interface RestaurantSocialMedia {
  instagram?: string;
  facebook?: string;
  twitter?: string;
  youtube?: string;
}

export interface RestaurantState {
  id?: number;
  slug?: string;
  info?: RestaurantInfo;
  address?: RestaurantAddress;
  social_media?: RestaurantSocialMedia;
}

export interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

export interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

export interface PlaceType {
  place_id?: string;
  types?: string[];
  formatted_address?: string;
  description?: string;
  structured_formatting?: StructuredFormatting;
}

export interface RestaurantAction extends Action<RestaurantState> {
  type: RestaurantActionTypes;
}
