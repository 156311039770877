import { Action } from 'types/index';

export type AvailableSlot = {
  timestamp: number;
  formatted_timestamp: string;
  available: boolean;
  available_booking_length: number;
  max_booking_length: number;
  min_booking_length: number;
  tables: number[];
  guests: number;
};

export type AvailableSlots = AvailableSlot[];

export type AvailableSlotsState = AvailableSlots;

export type AvailableSlotsResponse = AvailableSlots;

export type UpdateAvailableSlotsPayload = {
  start_timestamp: number;
  end_timestamp: number;
  guests: number;
  available_slots: AvailableSlots;
};

export enum AvailableSlotsActionTypes {
  UpdateMultipleAvailableSlots = 'AvailableSlots.UpdateMultiple',
}

interface UpdateAvailableSlotsAction extends Action<UpdateAvailableSlotsPayload> {
  type: AvailableSlotsActionTypes.UpdateMultipleAvailableSlots;
  payload: UpdateAvailableSlotsPayload;
}

export type AvailableSlotsAction = UpdateAvailableSlotsAction;
