import { Action } from 'types/index';

export enum ClientsActionTypes {
  MergeClients = 'Clients.UpdateClients',
  AddOrUpdateClient = 'Clients.UpdateClient',
}

export type ReservationStats = {
  visited: number;
  no_shows: number;
  cancelled: number;
  last_visit: string;
};

export type FeedbackStats = {
  total: number;
  rating: number;
};

export type ClientStats = {
  reservation: ReservationStats;
  feedback: FeedbackStats;
};

export type Client = {
  id: number;
  stats: ClientStats;
};

export type Clients = Client[];

export type ClientsState = Clients;

interface UpdateMultipleClientsAction extends Action<Clients> {
  type: ClientsActionTypes.MergeClients;
  payload: Clients;
}

interface UpdateClientAction extends Action<Client> {
  type: ClientsActionTypes.AddOrUpdateClient;
  payload: Client;
}

export type ClientsAction = UpdateMultipleClientsAction | UpdateClientAction;
