import { Action } from 'types/index';
import { Origin, Statuses } from 'types/reservation';
import { IFile } from 'types/app/file';

export enum ReservationsActionTypes {
  CreateReservation = 'Reservations.CreateReservation',
  UpdateReservation = 'Reservations.UpdateReservation',
  UpdateReservationTime = 'Reservations.UpdateReservationTime',
  UpdateBaseReservations = 'Reservations.UpdateBaseReservations',
  UpdateFullReservations = 'Reservations.UpdateFullReservations',
  DeleteReservation = 'Reservations.DeleteReservation',
  FindReservationAndSetComment = 'Reservations.FindReservationAndSetComment',
  FindReservationAndDeleteComment = 'Reservations.FindReservationAndDeleteComment',
  SetReservationLoading = 'Reservations.SetReservationLoading',
}

export interface IReservationAdditionalInfo {
  short_info?: string | null;
  birthday?: string | null;
  family_member?: string | null;
  favourites?: string | null;
  description?: string | null;
}

export interface IReservationClient {
  id?: number;
  name?: null | string;
  note?: null | string;
  email?: null | string;
  phone?: null | string;
  company?: null | string;
  subscribed?: boolean;
  vip?: boolean;
  big_spender?: boolean;
  blacklisted?: boolean;
  tags?: string[];
  stats?: {
    visited?: number;
    no_showed?: number | null;
    cancelled?: number | null;
    last_visit?: string | null;
  };
  additional_info?: IReservationAdditionalInfo;
}

export interface IComment {
  text: string;
  user_id?: number;
  changed?: number;
  comment_id?: number;
  id?: number;
}

export interface IPayment {
  payment_id: number;
  reservation_id: number;
  currency: string;
  amount: number;
  status: string;
  created: number;
}

export interface IStripePayment {
  id: string;
  object: string;
  cancellation_reason: string | null;
  client_secret: string;
  created: number;
  description: string | null;
  last_setup_error: any;
  livemode: boolean;
  next_action: any;
  payment_method: string;
  payment_method_types: string[];
  status: string;
  usage: string;
  paymentSuccess: boolean;
  paidAmount: number;
  paidAt: string;
}

export interface ILateCancellation {
  special_offer_id: number;
  is_enabled: boolean;
  time: number;
  charge_mode: string;
  maximum_amount: number;
  currency: string;
}

export interface IWidgetField {
  key: string;
  value: string | boolean;
}

export interface IReservationData {
  reservation_date?: number | null;
  status: Statuses;
  start_time: number;
  end_time: number;
  guests: number;
  tables: string[];
  files: IFile[];
  created_at?: number;
  updated_at?: number;
  original_start_time?: number | null;
  origin: Origin;
  important_message?: null | string;
  lock_table?: boolean;
  comments?: IComment[];
  guest_comments?: IComment[];
  waiter_id?: null | number;
  special_offer?: null | {
    id: number;
    type: string;
    late_cancellation?: ILateCancellation;
  };
  stripe_payment_data?: IStripePayment | null;
  payments: IPayment[] | null;
  widget_fields: IWidgetField[];
}

export interface IReservationNotifications {
  sms?: boolean;
  email?: boolean;
  resend?: boolean;
  table_ready?: boolean;
  language?: string;
}

export interface IReservation extends IReservationData {
  id: number | string;
  tags?: string[];
  notifications?: IReservationNotifications;
  client?: IReservationClient;
  isLoading?: boolean;
}

export type ReservationsState = IReservation[];

export type UpdateReservationsState = {
  time: {
    start: number;
    end: number;
  };
  reservations: IReservation[];
};

export type UpdateReservationTime = {
  id: number;
  start: number;
  end: number;
  tables: string[];
};

interface UpdateReservationTimeAction extends Action<UpdateReservationTime> {
  type: ReservationsActionTypes.UpdateReservationTime;
  payload: UpdateReservationTime;
}

interface CreateReservationAction extends Action<IReservation> {
  type: ReservationsActionTypes.CreateReservation;
  payload: IReservation;
}

interface UpdateReservationAction extends Action<IReservation> {
  type: ReservationsActionTypes.UpdateReservation;
  payload: IReservation;
}

interface BaseReservationsAction extends Action<ReservationsState> {
  type: ReservationsActionTypes.UpdateBaseReservations;
  payload: ReservationsState;
}

interface FullReservationsAction extends Action<UpdateReservationsState> {
  type: ReservationsActionTypes.UpdateFullReservations;
  payload: UpdateReservationsState;
}

interface DeleteReservationAction extends Action<number> {
  type: ReservationsActionTypes.DeleteReservation;
  payload: number;
}

interface FindReservationAndSetComment {
  type: ReservationsActionTypes.FindReservationAndSetComment;
  payload: {
    reservationId: number;
    comment: IComment;
  };
}

interface FindReservationAndDeleteComment {
  type: ReservationsActionTypes.FindReservationAndDeleteComment;
  payload: {
    reservationId: number;
    commentId: number;
  };
}

interface SetReservationLoading {
  type: ReservationsActionTypes.SetReservationLoading;
  payload: {
    reservationId: number;
    loadingStatus: boolean;
  };
}

export type ReservationsAction =
  | CreateReservationAction
  | UpdateReservationAction
  | UpdateReservationTimeAction
  | BaseReservationsAction
  | FullReservationsAction
  | DeleteReservationAction
  | FindReservationAndSetComment
  | FindReservationAndDeleteComment
  | SetReservationLoading;
